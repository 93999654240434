import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({on:{"paramsChanged":_vm.reloadCollection},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.canCreate)?_c(VBtn,{attrs:{"depressed":"","color":"primary","to":{ name: 'client-create' }}},[_vm._v(" "+_vm._s(_vm.$t('Add new client'))+" ")]):_vm._e()]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'client-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'client-update', params: { id: item.id } }}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('client-action-delete',{attrs:{"value":item,"icon":""},on:{"success":_vm.onDeleteSuccess,"error":_vm.onRequestError}})]}},{key:"item.code",fn:function(ref){
var value = ref.value;
return [_c('code',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(value))])]}},{key:"item.providers",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(Array.isArray(value) ? value.join(', ') : ''))])]}},{key:"item.roles",fn:function(ref){
var value = ref.value;
return [(Array.isArray(value))?[_c('span',{domProps:{"innerHTML":_vm._s(value.map(function (role) { return ("<span class='text-no-wrap'>" + (role.name) + "</span>"); }).join('<br />'))}})]:[_c('span',[_vm._v(_vm._s(_vm.$t('N/A')))])]]}},{key:"item.permissions",fn:function(ref){
var value = ref.value;
return [(Array.isArray(value))?[_c('span',{domProps:{"innerHTML":_vm._s(value.map(function (role) { return ("<span class='text-no-wrap'>" + (role.name) + "</span>"); }).join('<br />'))}})]:[_c('span',[_vm._v(_vm._s(_vm.$t('N/A')))])]]}}])},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options, title: _vm.$t('Clients') },false))}
var staticRenderFns = []

export { render, staticRenderFns }