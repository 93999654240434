var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('role-name',{staticClass:"required",attrs:{"error-messages":_vm.errors['name']},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('div',{staticClass:"d-flex flex-column flex-gap-16"},[_c('div',{staticClass:"d-flex flex-column required",class:{
        'grey--text': !_vm.canReadPermissions,
        'error--text': Object.keys(_vm.chipErrors('permission_ids').value).length
      }},[(!_vm.canReadUsers)?_c('no-permissions-tooltip'):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('Permissions'))+":")]),(_vm.errors['permission_ids'])?_c('span',{staticClass:"text-caption error--text"},[_vm._v(" "+_vm._s(_vm.$t('Required at least one'))+" "+_vm._s(_vm.$t('permission'))+" ")]):_vm._e()],1),(_vm.canReadPermissions)?_c('role-permissions',{attrs:{"errors":_vm.chipErrors('permission_ids').value},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}):_c('deletable-chips',{attrs:{"value":_vm.role.permissions || [],"show-delete-icon":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}])})],1),_c('div',{staticClass:"d-flex flex-column flex-gap-16"},[_c('div',{staticClass:"d-flex flex-column",class:{ 'grey--text': !_vm.canReadUsers, 'error--text': Object.keys(_vm.chipErrors('user_ids').value).length }},[(!_vm.canReadUsers)?_c('no-permissions-tooltip'):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('Admins'))+":")])],1),(_vm.canReadUsers)?_c('role-users',{attrs:{"errors":_vm.chipErrors('user_ids').value},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}):_c('deletable-chips',{attrs:{"value":_vm.role.users || [],"show-delete-icon":false}})],1),_c('div',{staticClass:"d-flex flex-column flex-gap-16"},[_c('div',{staticClass:"d-flex flex-gap-16",class:{
        'grey--text': !_vm.canReadClients,
        'error--text': Object.keys(_vm.chipErrors('client_ids').value).length
      }},[(!_vm.canReadClients)?_c('no-permissions-tooltip'):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('Clients'))+":")])],1),(_vm.canReadClients)?_c('role-clients',{attrs:{"errors":_vm.chipErrors('client_ids').value},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}):_c('deletable-chips',{attrs:{"value":_vm.role.clients || [],"show-delete-icon":false}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }