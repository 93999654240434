import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":true,"max-width":"600","persistent":""}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t('Client created')))]),_c(VDivider),_c(VCardText,{staticClass:"pa-4"},[_c(VAlert,{attrs:{"type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t("Please store your secret, because you won't be able to see it once you close this view"))+" ")]),_c(VCard,{staticClass:"d-flex justify-space-between blue-grey lighten-5 pa-4",attrs:{"flat":""}},[_c('pre',[_vm._v(_vm._s(_vm.value.secret))]),_c('copy-button-action',{attrs:{"value":_vm.value.secret}})],1)],1),_c(VDivider),_c(VCardActions,{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"depressed":"","color":"primary","to":{ name: 'client-update', params: { id: _vm.value.id } }}},[_vm._v(" "+_vm._s('OK')+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }